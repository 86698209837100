<template>
    <div class="flex container box-bb flex-jc-sb">
        <div
            class="tab flex"
            v-for="(o, i) in tabMsg"
            :key="i"
            :class="{
                isClick: i !== 'acceptSendNumCopy',
            }"
            @click="toPath(o)"
            :style="{ backgroundColor: o.bg_color, transform: `scale(${scale})` }"
        >
            <div class="left tab_info flex flex-ai-fs flex-jc-sb">
                <div class="text" :style="{ transform: `scale(${scale})` }">{{ o.text }}</div>
                <div class="num" :style="{ color: o.color, transform: `scale(${scale})` }">{{ o.num }}</div>
            </div>
            <div class="tab_img" :style="{ transform: `scale(${scale})` }">
                <img :src="o.img" alt="" class="img-r" />
            </div>
        </div>
    </div>
</template>

<script>
import { pendingNumber, statisticsNumber } from '@/api/modular/fileStream/documentManage'
export default {
    name: 'tab',
    data() {
        return {
            scale: 1, //
            tabMsg: {
                auditimgNum: {
                    route: {
                        path: '/fileStreamDocumentApproval',
                        query: {
                            index: 1,
                        },
                    },
                    text: '待审批公文',
                    img: require('@/assets/img/home/dsh.png'),
                    num: 0,
                    color: 'rgb(255, 114, 114)',
                    bg_color: 'rgba(255, 114, 114, 0.15)',
                },
                unReceivedNum: {
                    route: {
                        path: '/fileStreamDocumentAccept',
                        query: {
                            index: 1,
                        },
                    },
                    text: '待接收公文',
                    img: require('@/assets/img/home/dqs.png'),
                    num: 0,
                    color: 'rgb(23, 208, 177)',
                    bg_color: 'rgba(23, 208, 177, 0.15)',
                },
                auditedNum: {
                    route: {
                        path: '/fileStreamDocumentApproval',
                        query: {
                            index: 2,
                        },
                    },
                    text: '已审批公文',
                    img: require('@/assets/img/home/ysh.png'),
                    num: 0,
                    bg_color: 'rgba(120, 163, 255, 0.15)',
                    color: 'rgb(120, 163, 255)',
                },
                receivedNum: {
                    route: {
                        path: '/fileStreamDocumentAccept',
                        query: {
                            index: 2,
                        },
                    },
                    text: '已签收公文',
                    img: require('@/assets/img/home/yqs.png'),
                    num: 0,
                    color: 'rgb(164, 85, 255)',
                    bg_color: 'rgba(164, 85, 255, 0.15)',
                },
                acceptSendNum: {
                    route: {
                        path: '/fileStreamArchived',
                        query: {
                            index: 0,
                        },
                    },
                    text: '文书归档',
                    img: require('@/assets/img/home/zsfs.png'),
                    num: 0,
                    color: 'rgb(255, 175, 54)',
                    bg_color: 'rgba(255, 175, 54, 0.15)',
                },
            },
        }
    },
    mounted() {
        this.getStatisticsNumber()
        this.initNumber()
        // window.addEventListener('resize', () => {
        //   this.scale = document.body.offsetWidth / 1920
        // })
    },
    methods: {
        getStatisticsNumber() {
            statisticsNumber()
                .then((res) => {
                    if (res.code === 200) {
                        const keys = Object.keys(res.data)
                        keys.forEach((element) => {
                            this.tabMsg[element].num = res.data[element]
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        initNumber() {
            pendingNumber()
                .then((res) => {
                    if (res.code === 200) {
                        this.tabMsg['auditimgNum'].num = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        toPath({ route }) {
            if (route) this.$router.push(route)
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}

.container {
    background: #ffffff;
    padding: 15px;
    border-radius: 3px;
    overflow-x: auto;
    min-height: 180px;
}

.tab {
    width: 19%;
    min-width: 265px;
    height: 138px;
    margin-right: 15px;

    // box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    // padding: 28px;
    // margin-right: 30px;
    overflow: hidden;
    cursor: pointer;

    &.isClick {
        cursor: pointer;
    }

    .tab_info {
        padding: 15px 0 15px 15px;
        flex-direction: column;
        flex-wrap: nowrap;
        white-space: nowrap;

        .num {
            font-size: 48px;
            font-weight: bold;
        }

        .text {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .tab_img {
        .img-r {
            width: 138px;
            height: 138px;
        }
    }
}

.tab:last-child {
    margin-right: 0;
}
</style>
 